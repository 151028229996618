<template>
	<!-- Map Legend Collapsible -->
	<map-legend-control :isLegendCompareMap2="isLegendCompareMap2">
		<template>
			<v-expand-transition>
				<v-card flat max-height="290px" class="d-flex flex-column" v-if="isLegendCompareMap2 ? legendExpandedCompareMap : legendExpanded">
					<div class="d-flex align-start flex-column pa-1" style="overflow-y: auto; max-width: 100%">
						<!-- Layers legend -->
						<div v-for="(layer, i) in layersIcons" :key="i" class="d-flex align-center pl-2 py-1">
							<v-img
								contain
								aspect-ratio="1"
								max-height="20"
								min-height="20"
								max-width="20"
								min-width="20"
								:src="getLayerIcon(layer)"
								:alt="$t('grid.pmdatasourcetype.iconname')"
								class="mr-1"
							></v-img>
							<!-- <svg-icon :icon="getLayerIcon(layer)" :width="20"></svg-icon> -->
							<div class="maplegendcontrol__row-element ml-2 fs-regular">{{ layer.title }}</div>
						</div>
						<!-- Raster Legend Map Settings -->
						<img v-if="isRasterSettingsActive" class="wmslegendgraphic" :src="urlImageRasterSettings" />

						<!-- Raster Legend Compare Map 1 -->
						<div v-if="isRasterCompareMap1Active" class="maplegendcontrol__row-element fs-regular width-100 text-center">
							{{ titleRasterCompareMap1 + ' ' + dateMap1 }}
						</div>
						<img v-if="isRasterCompareMap1Active && !isLegendCompareMap2" class="wmslegendgraphic" :src="urlImageRasterCompareMap1" />

						<!-- Raster Legend Compare Map 2 -->
						<div v-if="isRasterCompareMap2Active" class="maplegendcontrol__row-element fs-regular width-100 text-center">
							{{ titleRasterCompareMap2 + ' ' + dateMap2 }}
						</div>
						<img v-if="isRasterCompareMap2Active && isLegendCompareMap2" class="wmslegendgraphic" :src="urlImageRasterCompareMap2" />
						<div
							v-if="!isRasterSettingsActive && !isRasterCompareMap1Active && !isRasterCompareMap2Active && layersIcons.length === 0"
							class="width-100 d-flex justify-center"
						>
							<span class="grey--text">{{ $t('common.noData') }}</span>
						</div>
					</div>
					<!-- Show Full Legend -->
					<!-- <v-card-actions class="d-flex align-center justify-center pa-0">
						<v-btn @click="openModal" color="var(--azul-primary-100)" text style="font-size: 0.9rem" class="py-2 width-100 fw-500">
							<v-icon left size="0.9rem" class="ma-0 mr-1">fal fa-list</v-icon>
							<span style="font-family: var(--font-family-primary)">
								{{ $t('legend.showall') }}
							</span>
						</v-btn>
					</v-card-actions> -->
				</v-card>
			</v-expand-transition>
			<!-- Modal Dialog Full Legend -->
			<map-legend-dialog v-model="modalOpened" />
		</template>
	</map-legend-control>
</template>

<script>
import MapLegendDialog from './MapLegendDialog';
import MapLegendControl from './MapLegendControl';
//import SvgIcon from '@/components/map/icon/SvgIcon';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import constants from '@/utils/constants';
import { getIconsTypeIcon } from '@/api/common';

import LayersActiveMixin from '@/mixins/LayersActiveMixin';
import { debounce } from 'lodash';
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'maplegendbody',

	props: {
		isLegendCompareMap2: {
			type: Boolean,
			required: false
		},
		isMapLoaded: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			layersIcons: [],
			modalOpened: false,

			handleUpdate: debounce(this._handleUpdate, 250),
			updateLayersIcons: debounce(this._updateLayersIcons, 250),
			urlImageRasterSettings: null,
			titleRasterCompareMap1: null,
			urlImageRasterCompareMap1: null,
			titleRasterCompareMap2: null,
			urlImageRasterCompareMap2: null,
			rasterId1: constants.RASTER.MAP1,
			rasterId2: constants.RASTER.MAP2,
			dateMap1: '',
			dateMap2: ''
		};
	},

	mixins: [LayersActiveMixin],

	components: {
		MapLegendDialog,
		MapLegendControl
		/* SvgIcon */
	},

	computed: {
		legendExpanded() {
			return this.$store.state.map.legendExpanded;
		},
		legendExpandedCompareMap() {
			return this.$store.state.mapCompare.legendExpanded;
		},

		isRasterSettingsActive() {
			if (this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID) {
				return false;
			} else {
				return this.urlImageRasterSettings == null ? false : true;
			}
		},
		isRasterCompareMap1Active() {
			if (this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID && !this.isLegendCompareMap2) {
				return this.urlImageRasterCompareMap1 == null ? false : true;
			} else {
				return false;
			}
		},
		isRasterCompareMap2Active() {
			if (this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID && this.isLegendCompareMap2) {
				return this.urlImageRasterCompareMap2 == null ? false : true;
			} else {
				return false;
			}
		},

		layers() {
			return this.$store.getters.layers;
		},
		rasterDateSelected() {
			return this.$store.getters.getRasterDateSelected;
		}
	},
	watch: {
		/* isRasterCompareMap1Active(val) {
			console.log('isRasterCompareMap1Active', val, this.rasterDateSelected);
			if (val) {
				this.dateMap1 = this.rasterDateSelected[1];
			}
		},
		isRasterCompareMap2Active(val) {
			console.log('isRasterCompareMap2Active', val, this.rasterDateSelected);
			if (val) {
				this.dateMap2 = this.rasterDateSelected[2];
			}
		}, */
		'$store.state.mappanel.layers': {
			handler() {
				this.handleUpdate();
			},
			deep: true
		},

		'$store.state.mappanel.mapLoaded'() {
			this.handleUpdate();
		},

		legendExpanded() {
			if (this.legendExpanded) {
				this._handleUpdate();
			}
		},
		legendExpandedCompareMap() {
			if (this.legendExpandedCompareMap) {
				this._handleUpdate();
			}
		},

		'$store.state.mappanel.rasterLayersSettings': {
			handler() {
				var lyrActiveId = Object.keys(this.$store.state.mappanel.rasterLayersSettings).find(
					(lyr) => this.$store.state.mappanel.rasterLayersSettings[lyr]['active'] == true
				);
				if (lyrActiveId) {
					var lyr = ol2map.getLayerById(lyrActiveId);
					this.urlImageRasterSettings =
						lyr.getSource().getLegendUrl() +
						'&LAYER=' +
						lyr.values_.id +
						'&WIDTH=230&HEIGHT=50' +
						'&LEGEND_OPTIONS=fontSize:36;fontName:Montserrat;';
				} else {
					this.urlImageRasterSettings = null;
				}
			},
			deep: true
		},

		'$store.state.mappanel.rasterLayersCompareMap1': {
			handler() {
				var lyrActiveId = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap1).find(
					(lyr) => this.$store.state.mappanel.rasterLayersCompareMap1[lyr]['active'] == true
				);
				if (lyrActiveId) {
					var lyr = ol2map.getLayerById(lyrActiveId);
					this.urlImageRasterCompareMap1 =
						lyr.getSource().getLegendUrl() +
						'&LAYER=' +
						lyr.values_.id +
						'&WIDTH=230&HEIGHT=50' +
						'&LEGEND_OPTIONS=fontSize:36;fontName:Montserrat;';
					this.titleRasterCompareMap1 = lyr.values_.title;
				} else {
					this.urlImageRasterCompareMap1 = null;
					this.titleRasterCompareMap1 = null;
				}
			},
			deep: true
		},

		'$store.state.mappanel.rasterLayersCompareMap2': {
			handler() {
				var lyrActiveId = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap2).find(
					(lyr) => this.$store.state.mappanel.rasterLayersCompareMap2[lyr]['active'] == true
				);
				if (lyrActiveId) {
					var lyr = ol2map.getLayerById(lyrActiveId);
					this.urlImageRasterCompareMap2 =
						lyr.getSource().getLegendUrl() +
						'&LAYER=' +
						lyr.values_.id +
						'&WIDTH=230&HEIGHT=50' +
						'&LEGEND_OPTIONS=fontSize:36;fontName:Montserrat;';
					this.titleRasterCompareMap2 = lyr.values_.title;
				} else {
					this.urlImageRasterCompareMap2 = null;
					this.titleRasterCompareMap2 = null;
				}
			},
			deep: true
		}
	},
	updated() {
		this.dateMap1 = this.rasterDateSelected[1];
		this.dateMap2 = this.rasterDateSelected[2];
		//console.log('MapLegendBody updated', this.rasterDateSelected, this.isLegendCompareMap2, this.$store.getters.getRasterDateSelected);
	},
	destroyed() {
		//this.$puiEvents.$off('map:onViewStateChange');
	},
	methods: {
		openModal() {
			this.$emit('update', false);
			this.modalOpened = true;
		},

		closeDialog() {
			this.modalOpened = false;
		},

		_handleUpdate() {
			if (!this.legendExpanded && !this.legendExpandedCompareMap) {
				return;
			}

			this._updateLayersIcons();
		},

		async _updateLayersIcons() {
			this.layersIcons = [];

			Object.keys(this.layers).forEach((layer) => {
				if (this.layers[layer].active) {
					this.layersIcons.push(this.layers[layer]);
				}
			});
		},
		getLayerIcon(layer) {
			const urlBase = this.$store.getters.getApplication.urlbase;
			let iconSource;
			// TODO: Make generic implementation (maybe array of models)
			switch (layer.model) {
				case constants.DEVICES_MODEL:
					iconSource = layer.datasourcetypeicon;
					break;
				case constants.NOTIFICATIONS_MODEL:
					iconSource = getIconsTypeIcon(
						constants.NOTIFICATIONS_ICONS_TYPE,
						constants.NOTIFICATIONS_TAB_ACRONYM,
						this.$store.getters.getIcons
					).route;
					break;
				case constants.AREA_NOTIFICATION: {
					const color = layer.color || "#FF5733"; // Usa el color de areaDefinition, con un fallback
					// SVG con rectángulo superpuesto
					const svg = `
						<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
							<rect width="20" height="8" x="0" y="6" fill="${color}" />
						</svg>
					`;
					// Asegura que la cadena SVG esté bien codificada
					const encodedSvg = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
					iconSource = encodedSvg;
					return iconSource; // NO concatenamos urlBase aquí
				}
				default:
					iconSource = layer.datasourcetypeicon;
					break;
			}
			return urlBase + iconSource;
		}
	}
};
</script>

<style lang="postcss">
.wmslegendgraphic {
	width: 230px;
	max-width: 250px;
}
</style>
