<template>
	<article
		class="card rounded feature-card"
		id="feature-card-item"
		style="border-top-right-radius: 8px; border-top-left-radius: 8px"
		v-if="feature"
	>
		<slot name="header">
			<header class="card__header overflow-x-auto py-6p px-10p">
				<v-row no-gutters class="">
					<v-col cols="8" class="d-flex flex-nowrap align-center pa-0">
						<div style="border-radius: 50%; background-color: white" class="size-xs d-flex align-center justify-center mr-1">
							<v-img
								v-if="featureIcon"
								contain
								aspect-ratio="1"
								max-height="18"
								max-width="18"
								:src="featureIcon"
								:alt="$t('grid.pmdatasourcetype.iconname')"
							></v-img>
							<v-progress-circular :width="3" :size="30" color="white" indeterminate v-else></v-progress-circular>
						</div>

						<span class="text-uppercase station-code color-white fw-bold pa-0">{{ feature.code }}</span>
						<span v-if="featureArea" class="fw-regular fs-regular color-white pa-0 pl-3">{{ featureArea }}</span>
						<span
							v-if="feature.spatialmodel != satelliteModel && feature.longitude && feature.latitude"
							class="fw-regular fs-sm color-white pa-0 pl-2"
							>({{ feature.longitude }} N, {{ feature.latitude }}E)
						</span>
						<span v-else-if="mobileCoordinates.length > 0" class="fw-regular fs-sm color-white pa-0 pl-2"
							>({{ mobileCoordinates[0].toFixed(4) }} N, {{ mobileCoordinates[1].toFixed(4) }}E)
						</span>
					</v-col>
					<v-col cols="4" class="pa-0">
						<slot name="action-button" class="align-center justify-end card__button"></slot>
					</v-col>
				</v-row>
			</header>
		</slot>
		<div class="card-content-container" id="card-container">
			<devicePanel v-if="type == device" :deviceObject="feature" :displayLocation="displayLocation" />
			<notificationPanel v-else-if="type == notification" :notificationObject="feature" :displayLocation="displayLocation" />
		</div>
	</article>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

import constants from '@/utils/constants';
import { getStringFeatureImage, getAreaFromCoordinates } from '@/api/common';

import devicePanel from './device/DeviceIndex.vue';
import notificationPanel from './notification/NotificationIndex.vue';
//import { CARD_CONTAINER_HEIGHT, CARD_CONTAINER_HEIGHT_DIALOG } from '@/lib/variables/panels';

import ol2map from '../subcomponents/ol2map';
import * as olProj from 'ol/proj';

export default {
	mixins: [PuiGridDetailMixin],
	components: {
		devicePanel,
		notificationPanel
	},
	props: {
		feature: {
			type: Object,
			required: true
		},
		vertical: {
			type: Boolean,
			default: true
		},
		type: {
			type: String,
			required: false,
			default: constants.FEATURE_TYPE_DEVICE
		}
		/* displayMode: {
			type: String,
			required: true,
			default: 'card'
		} */
	},
	data() {
		return {
			tab: null,
			device: constants.FEATURE_TYPE_DEVICE,
			notification: constants.FEATURE_TYPE_NOTIFICATION,
			satelliteModel: constants.SATELLITE_MODEL,
			/* cardContainerStyle: {
				height: CARD_CONTAINER_HEIGHT
			},
			dialogContainerStyle: {
				height: '60vh'
			}, */
			displayLocation: 'card',
			mobileCoordinates: [],
			featureArea: null,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		bodyStyle() {
			return {
				//'flex-direction': this.vertical ? 'column' : 'row',
				'padding-top': this.title ? '0px' : '16px'
			};
		},
		featureIcon() {
			return this.getFeatureIcon(this.type);
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	watch: {
		feature(val) {
			console.log('watch feature', val, val.ismobile, this.featureArea);
			if (val.ismobile == 1) {
				this.mobileCoordinates = this.getMobileCoordinates(val);
				let areaName = getAreaFromCoordinates(this.mobileCoordinates[1], this.mobileCoordinates[0], this.$store.getters.getAreas);
				if (areaName) {
					this.featureArea = areaName;
				} else {
					this.featureArea = null;
				}
			} else {
				this.mobileCoordinates = [];
				this.featureArea = this.feature.pmareaname;
			}
		}
	},
	beforeMount() {},
	mounted() {
		if (this.feature.ismobile == 1) {
			this.mobileCoordinates = this.getMobileCoordinates(this.feature);
			let areaName = getAreaFromCoordinates(this.mobileCoordinates[1], this.mobileCoordinates[0], this.$store.getters.getAreas);
			if (areaName) {
				this.featureArea = areaName;
			} else {
				this.featureArea = null;
			}
		} else {
			this.featureArea = this.feature.pmareaname;
		}
		console.warn('feature', this.feature);
		console.warn('type', this.type);
	},
	updated() {
		console.warn('updated', this.$store.getters.getHistoricStore);
	},
	methods: {
		getFeatureIcon(featureType) {
			var appProperties = this.$store.getters.getApplication;
			var icon = null;
			switch (featureType) {
				case constants.FEATURE_TYPE_DEVICE:
					icon = getStringFeatureImage(appProperties.urlbase, this.feature.datasourcetypeicon);
					break;
				case constants.FEATURE_TYPE_NOTIFICATION:
					icon = getStringFeatureImage(appProperties.urlbase, this.feature.iconroute);
					break;
				default:
					icon = getStringFeatureImage(appProperties.urlbase, this.feature.datasourcetypeicon);
					break;
			}
			return icon;
		},
		getMobileCoordinates(feature) {
			let coordinates = [];
			Object.keys(this.$store.getters.layers).forEach((layerId) => {
				if (coordinates.length > 0) return olProj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');
				coordinates = ol2map.getMobileFeatureCoordinates(layerId, feature.code);
				console.log('debug foreach coordinates', this.$store.getters.layers, layerId, coordinates);
			});
			return olProj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');
		},
		getDeviceArea(feature) {
			console.log('getDeviceArea', feature);
			if (feature.ismobile == 1) {
				return 'Mobile';
			} else if (feature.pmareaname) {
				return feature.pmareaname;
			} else {
				return 'Area';
			}
		}
	}
};
</script>

<style lang="postcss">
@import './../../../../../styles/eiffel-variables.pcss';
.card {
	position: absolute;
	bottom: 1.1rem;
	z-index: 2;
	border: 1px solid var(--moderate);
	background: white;
	width: 98%;
	right: 1%;

	&__body {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		padding: 0 1.125em 0.625em 1.125em;
		height: 330px;
		overflow: hidden;
	}

	&__header {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 1.125em 1.125em 0.5em 1.125em;
		min-height: 42px;
		background-color: var(--azul-primary-100);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		& .station-code {
			font-size: 20px !important;
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		align-items: center;
	}

	&__title {
		color: #414141;
		font-size: 1.125rem;
	}

	&__button:hover {
		background: var(--primarycolor);

		& i {
			color: white;
		}
	}
}

#card-container {
	//transition: all 1s ease-out;
	height: var(--container-height) !important;
}
.card-content-tabs {
	//transition: max-height 1s ease-out;
	max-height: 50px;
}

label.v-label.theme--light {
	left: 8px !important;
	top: 5px !important;
}
label.v-label.v-label--active {
	left: 0px !important;
	top: -1px !important;
}
.v-select__selection--comma {
	margin-left: 7px !important;
}
.v-slide-group__prev--disabled {
	display: none !important;
}

.v-tabs-bar__content {
	& > .v-tab {
		color: #000 !important;
	}
	& > .v-tab--active {
		color: var(--azul-primary-100) !important;
	}
}
</style>
