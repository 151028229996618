import Map from 'ol/Map';
import * as olProj from 'ol/proj';
import { Icon, Style } from 'ol/style';
//import { Icon, Style, Fill, Circle, Stroke, Text } from 'ol/style';

import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorLayer } from 'ol/layer';
import * as olExtent from 'ol/extent';
import { Polygon } from 'ol/geom.js';

/** Styles */
import devicesStylePoint from '@/components/map/sections/map/styles/devicesStylePoint';
/** WMS */
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import WMS from 'ol/source/TileWMS';
/** WMTS */
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

import { BASEMAP_ID } from '@/components/map/sections/map/subcomponents/mapVariables';

import OSM from 'ol/source/OSM';
//import Pui9HttpRequests from '@Pui9Requests';

import { ZOOM_EXTENT_LAYER } from '@/components/map/sections/map/subcomponents/mapVariables';
import store from '@/store/store';

import { getStringFeatureImage, obtainIndexName } from '@/api/common';
import constants from '@/utils/constants';
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';

import { lastData } from '@/api/databases_API';
import { query_LatestByFieldValue } from '@/utils/queries';

export default {
	map: null,
	mapCompare: null,
	init(mapProps) {
		//console.log('debug new map', mapProps);
		this.map = new Map(mapProps);
		//console.log('this.map', this.map)
	},
	initMapCompare(mapProps) {
		this.mapCompare = new Map(mapProps);
	},
	getMapInstance() {
		return this.map;
	},
	getMapCompareInstance() {
		return this.mapCompare;
	},
	getAllLayers() {
		/*console.log("getAllLayers()")
		 */
		if (
			//store.state.mapCompare.eventClick ||
			store.getters.activePanel === COMPAREMAPS_PANEL_ID &&
			store.state.compareMapPanel.isOpened.layersrasterCompareMap2
		) {
			return this.mapCompare.getAllLayers();
		} else {
			return this.map.getAllLayers();
		}
	},

	getRasterActiveLayer() {
		var layers = this.getAllLayers();
		for (var i = 0; i < layers.length; i++) {
			var layer = layers[i];
			var visible = layer.getVisible();
			if (visible && layer instanceof ImageLayer) {
				return layer;
			}
		}
		return;
	},

	getZoomCompareMap() {
		return this.mapCompare.getView().getZoom();
	},
	getZoom() {
		return this.map.getView().getZoom();
	},

	getMapExtent() {
		return olProj.transformExtent(this.map.getView().calculateExtent(this.map.getSize()), 'EPSG:3857', 'EPSG:4326');
	},
	getLayerById(id) {
		var layers = this.getAllLayers();
		for (var i = 0; i < layers.length; i++) {
			var layer = layers[i];
			var layerId = layer.values_.id;
			if (layerId === id) {
				return layer;
			}
		}
		return;
	},
	addLayer(layer) {
		if (!layer.values_.id) {
			throw new Error(`[ol2map.addLayer] layer id must defined. Received "${layer.id}" instead`);
		}
		this.map.addLayer(layer);
	},

	addLayerCompareMap(layer) {
		if (!layer.values_.id) {
			throw new Error(`[ol2map.addLayer] layer id must defined. Received "${layer.id}" instead`);
		}

		if (this.mapCompare) {
			this.mapCompare.addLayer(layer);
		}
	},

	removeLayer(layer) {
		if (this.hasLayer(layer.values_.id)) {
			delete this.layers[layer.values_.id];
			this.map.removeLayer(layer);
		}
	},

	removeBaseLayerById(layerId) {
		if (this.hasLayer(layerId)) {
			var layer = this.map.getAllLayers().find((layer) => layer.get('id') == layerId);
			this.map.removeLayer(layer);
			console.log('changeBaseLayer removeBaseLayerById', layerId, !!this.mapCompare);
			if (this.mapCompare) {
				var layerMapCompare = this.mapCompare.getAllLayers().find((layer) => layer.get('id') == layerId);
				console.log('changeBaseLayer removeBaseLayerById 2', !!this.mapCompare.getAllLayers().find((layer) => layer.get('id') == layerId));
				if (layerMapCompare) {
					this.mapCompare.removeLayer(layerMapCompare);
				}
			}
		}
	},

	insertLayerAtPosition(position, layer) {
		this.map.getLayers().insertAt(position, layer);
		//console.log('changeBaseLayer insertLayerAtPosition', position, layer, !!this.mapCompare);
		if (this.mapCompare) {
			let layerCopy;
			const source = layer.getSource();
			//console.log('changeBaseLayer insertLayerAtPosition 2', layer instanceof TileLayer, source instanceof WMTS);
			if (layer instanceof TileLayer) {
				if (source instanceof WMTS) {
					layerCopy = new TileLayer({
						id: BASEMAP_ID,
						source: new WMTS({
							url: source.getUrls()[0],
							layer: source.getLayer(),
							matrixSet: source.getMatrixSet(),
							format: source.getFormat(),
							projection: source.getProjection(),
							tileGrid: new WMTSTileGrid({
								origin: source.getTileGrid().getOrigin(),
								resolutions: source.getTileGrid().getResolutions(),
								matrixIds: source.getTileGrid().getMatrixIds()
							}),
							style: source.getStyle(),
							attributions: source.getAttributions(),
							crossOrigin: 'anonymous'
						}),
						opacity: layer.getOpacity(),
						visible: layer.getVisible(),
						zIndex: layer.getZIndex()
					});
				} else if (source instanceof WMS) {
					layerCopy = new TileLayer({
						id: BASEMAP_ID,
						source: new WMS({
							url: source.getUrls()[0],
							params: source.getParams(),
							serverType: source.getServerType ? source.getServerType() : undefined,
							crossOrigin: 'anonymous'
						}),
						opacity: layer.getOpacity(),
						visible: layer.getVisible(),
						zIndex: layer.getZIndex()
					});
				} else if (source instanceof OSM) {
					layerCopy = new TileLayer({
						id: BASEMAP_ID,
						source: new OSM({
							attributions: source.getAttributions(),
							crossOrigin: 'anonymous'
						}),
						opacity: layer.getOpacity(),
						visible: layer.getVisible(),
						zIndex: layer.getZIndex()
					});
				} else {
					console.warn('Tipo de capa no soportado para clonación:', layer);
					return;
				}
			} else {
				console.warn('Tipo de capa no soportado para clonación:', layer);
				return;
			}
			this.mapCompare.getLayers().insertAt(position, layerCopy);
		}
	},

	hasLayer(id) {
		return !!this.getAllLayers().find((layer) => layer.get('id') == id);
	},
	createEmptyVectorLayer(id) {
		const vectorSource = new VectorSource({
			features: []
		});
		const vectorLayer = new VectorLayer({
			source: vectorSource,
			properties: {
				id: id
			}
		});
		return vectorLayer;
	},
	createIconVectorLayer(idLayer, vizOptions, devices, mobileCoordinates) {
		//console.log('create icon vector layer', idLayer);
		var self = this;
		const { viz } = vizOptions;

		const vectorSource = new VectorSource({
			properties: {
				id: idLayer
			},
			format: new GeoJSON({
				dataProjection: viz.featureProperties.projection,
				featureProjection: viz.featureProperties.featureProjection
			}),
			loader: function (extent, resolution, projection, success, failure) {
				if (devices.length > 0) {
					//response.success = true;
					var featuresArray = [];
					var appProperties = store.getters.getApplication;
					devices.forEach((element) => {
						let coordinates =
							element.ismobile === 1 && mobileCoordinates[element.code]
								? mobileCoordinates[element.code]
								: [element.longitude, element.latitude];
						var feature = self.createFeature(viz.featureProperties.type, element, coordinates);
						//console.log('viz debug', viz, viz.featureProperties.type);
						featuresArray.push(feature);
					});

					var data = self.createFeatureCollection(featuresArray);
					const features = vectorSource.getFormat().readFeatures(data);
					if (features.length == 0) {
						//console.log('No hay elementos para la capa: ' + idLayer);
						const disabled = true;
						store.commit('setDisabledItemLayer', { idLayer, disabled });
					} else {
						const enabled = true;
						store.commit('setDisabledItemLayer', { idLayer, enabled });
					}
					vectorSource.addFeatures(features);

					vectorLayer.getSource().forEachFeature(function (feature) {
						// Get icon src depending on feature's spatial_model
						var iconSource;
						var iconScale;
						feature.set('viz', viz);
						switch (viz.featureProperties.model) {
							case constants.DEVICES_MODEL:
								feature.setStyle(devicesStylePoint);
								break;
							case constants.NOTIFICATIONS_MODEL:
								iconSource = feature.values_['iconroute'];
								iconScale = viz.iconProperties.scale;

								var iconStyleNotifications = new Style({
									image: new Icon({
										opacity: viz.iconProperties.opacity,
										scale: iconScale,
										src: getStringFeatureImage(appProperties.urlbase, iconSource)
									})
								});

								feature.setStyle(iconStyleNotifications);
								break;
							default:
								iconSource = viz.featureProperties.datasourcetypeicon;
								iconScale = 0.25;

								var iconStyleDefault = new Style({
									image: new Icon({
										opacity: viz.iconProperties.opacity,
										scale: iconScale,
										src: getStringFeatureImage(appProperties.urlbase, iconSource)
									})
								});

								feature.setStyle(iconStyleDefault);
								break;
						}
						//console.log('iconStyle layer:' + viz.featureProperties.model);
					});

					success(features);
				} else {
					//console.log('-- Error creating the vector layer:' + idLayer);
					failure();
				}
			}
		});

		vectorSource.setProperties({ id: idLayer });

		const vectorLayer = new VectorLayer({
			format: new GeoJSON(),
			projection: viz.featureProperties.featureProjection,
			source: vectorSource,
			properties: {
				id: idLayer
			}
		});

		vectorSource.on('featuresloadend', (properties) => {
			if (ZOOM_EXTENT_LAYER == properties.target.values_.id && vectorLayer.getSource().getFeatures().length > 0) {
				var extent = vectorLayer.getSource().getExtent();
				this.map.getView().fit(extent, { size: this.map.getSize(), maxZoom: 16 });
			}
		});

		// Visibilidad de la capa inicialmente
		vectorLayer.setVisible(viz.layerProperties.visibility);
		this.addLayer(vectorLayer);
	},

	zoomToExtent(extent) {
		if (extent) {
			this.map.getView().fit(extent);
		} else {
			console.log('Error al hacer zoom a la extensión, la extension es nula');
		}
	},
	boundingExtent(extent) {
		if (extent) {
			return new olExtent.boundingExtent(extent);
		} else {
			console.log('Error al hacer boundingExtent, la extension es nula');
		}
	},
	zoomToTransformedExtent(extent, sourceProjection, destProjection) {
		if (extent) {
			const transformedExtent = olProj.transformExtent(extent, sourceProjection, destProjection);
			this.zoomToExtent(transformedExtent);
		} else {
			console.log('Error al hacer zoom, la extensión es nula');
		}
	},
	createPolygonGeometry(polygonCoordinates) {
		if (polygonCoordinates && polygonCoordinates.length > 0) {
			return new Polygon([polygonCoordinates]);
		} else {
			console.log('Error al crear la geometría del polígono, las coordenadas son nulas o vacías');
			return null;
		}
	},
	
	addFeaturesToVectorLayer(layerId, features) {
		if (this.hasLayer(layerId)) {
			var layer = this.getLayerById(layerId);
			layer.getSource().addFeatures(features);
		}
	},
	createFeatureCollection(featuresArray) {
		const featureCollection = {
			type: 'FeatureCollection',
			crs: {
				type: 'name',
				properties: {
					name: 'EPSG:4326'
				}
			},
			features: featuresArray
		};
		return featureCollection;
	},
	createMapFeature(propiedades,geometry) {
		if (geometry && propiedades) {
			return new Feature({
				geometry: geometry,
				...propiedades
			});
		} else {
			console.log('Error al crear la característica, la geometría o las propiedades son nulas');
			return null;
		}
	},
	createFeature(type, properties, geometry) {
		var feature = {
			type: 'Feature',
			properties: properties,
			geometry: {
				type: type,
				coordinates: geometry
			}
		};
		//console.log('create feature', type, properties, geometry, feature);
		return feature;
	},

	createWMSLayer(idLayer, vizOptions, getMap) {
		const { viz } = vizOptions;
		var mapProj = this.map.getView().getProjection();

		var optsWMS = {
			layers: viz.layerName,
			authkey: 'null',
			QUERY_LAYERS: viz.layerName
			//transparent: viz.transparent || false,
			//srs: viz.srs || mapProj,
			//crs: viz.srs || mapProj,
			//version: viz.version || '1.3.0',
			//format: viz.format || 'image/png'
		};
		const wmsSource = new ImageWMS({
			url: viz.url,
			params: optsWMS,
			ratio: viz.ratio,
			serverType: viz.serverType,
			projection: viz.srs || mapProj,
			crossOrigin: viz.crossOrigin
		});

		const wmsLayer = new ImageLayer({
			source: wmsSource,
			properties: {
				id: idLayer,
				title: viz.title
			}
		});

		if (getMap === this.map) {
			this.addLayer(wmsLayer);
		} else if (getMap === this.mapCompare) {
			this.addLayerCompareMap(wmsLayer);
		}

		wmsLayer.setVisible(false);
	},

	createWMTSLayer(idLayer, vizOptions) {
		const { viz } = vizOptions;

		var optsWMS = {
			layers: viz.layerName,
			authkey: 'null',
			QUERY_LAYERS: viz.layerName
		};

		const wmtsSource = new TileWMS({
			url: viz.url,
			params: optsWMS,
			ratio: viz.ratio,
			serverType: viz.serverType
		});

		const wmsLayer = new TileLayer({
			source: wmtsSource,
			properties: {
				id: idLayer
			}
		});

		this.addLayer(wmsLayer);
	},

	refreshLayer(id, devices) {
		//var self = this;
		//console.log("refreshLayer")
		if (this.hasLayer(id)) {
			var layer = this.getLayerById(id);
			let mobileFeatures = layer
				.getSource()
				.getFeatures()
				.filter((feature) => feature['values_'] && feature['values_'].ismobile === 1);
			let staticFeatures = layer
				.getSource()
				.getFeatures()
				.filter((feature) => feature['values_'] && feature['values_'].ismobile === 0);
			//console.log('debug layer', id, staticFeatures, mobileFeatures);
			if (mobileFeatures.length > 0) {
				lastData(obtainIndexName(mobileFeatures[0]['values_']), query_LatestByFieldValue('device.keyword')).then(({ message }) => {
					//console.log("message", message)
					if (typeof message !== 'string') {
						message.forEach((result) => {
							let code = result.fields['device.keyword'][0];
							let coordinates = result['inner_hits'].latest.hits.hits[0]['_source'].location.coordinates;
							let feature = mobileFeatures.find((feature) => feature['values_'].code == code);
							let device = devices.find((device) => device.code == feature['values_'].code);
							if (feature) {
								let newFeature = new Feature(new Point(olProj.fromLonLat(coordinates)));
								newFeature.setProperties(device);
								newFeature.set('viz', feature.get('viz'));
								newFeature.setStyle(devicesStylePoint);

								layer.getSource().addFeature(newFeature);
							}
						});
					}
				});
				mobileFeatures.forEach((feature) => {
					layer.getSource().removeFeature(feature);
				});
			}
			if (staticFeatures.length > 0) {
				staticFeatures.forEach((feature) => {
					layer.getSource().removeFeature(feature);
					let device = devices.find((device) => device.code == feature['values_'].code);
					let coordinates = device ? [device.longitude, device.latitude] : [feature['values_'].longitude, feature['values_'].latitude];
					let newFeature = new Feature(new Point(olProj.fromLonLat(coordinates)));
					newFeature.setProperties(device ? device : feature.getProperties());
					newFeature.set('viz', feature.get('viz'));
					//newFeature.setProperties(feature.getProperties());
					newFeature.setStyle(devicesStylePoint);
					layer.getSource().addFeature(newFeature);
					//console.log('debug static feature', newFeature, feature['values_'].code);
				});
			}
		}
	},
	getMobileFeatureCoordinates(layerId, identifier) {
		if (this.hasLayer(layerId)) {
			var layer = this.getLayerById(layerId);
			let feature = layer
				.getSource()
				.getFeatures()
				.find((feature) => feature.getProperties() && feature.getProperties().code === identifier);
			let coordinates = [];
			if (feature) {
				coordinates = feature.getGeometry().getCoordinates();
			}
			return coordinates;
		}
		return;
	},

	setVisibility(id, visible) {
		if (this.hasLayer(id)) {
			var layer = this.getLayerById(id);
			//console.log('debug layer visibility', layer, visible);
			layer.setVisible(visible);
			layer.setOpacity(visible ? 1 : 0);
		}
	},

	setOpacity(id, value) {
		if (this.hasLayer(id)) {
			var layer = this.getLayerById(id);
			layer.setOpacity(value);
		}
	},
	setZoom(zoom) {
		this.map.getView().setZoom(zoom);
	},
	addOverlay(overlay, getMap) {
		if (!overlay.id) {
			throw new Error(`[ol2map.overlay] layer id must defined. Received "${overlay.id}" instead`);
		}
		getMap.addOverlay(overlay);
	},
	getCenterCoordinates() {
		return olProj.transform(this.map.getView().getCenter(), this.map.getView().getProjection().getCode(), 'EPSG:4326');
	}
};
