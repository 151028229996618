<template>
	<div class="maptimeclock rounded fw-500 fs-sm">{{ now }}</div>
</template>

<script>
import { formatTimestamp } from '@/api/common';
export default {
	name: 'Maptimeclock',
	data() {
		return {
			now: '',
			timer: null
		};
	},
	created() {
		this.now = formatTimestamp(Date.now(), this.userProperties);
		this.timer = setInterval(() => {
			this.now = formatTimestamp(Date.now(), this.userProperties);
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.timer);
	}
};
</script>

<style scoped>
.maptimeclock {
	position: absolute;
	top: 1rem;
	z-index: 2;
	left: 3.5rem;
	background-color: white;
	color: black;
	opacity: 0.9 !important;
	padding: 4px;
}
</style>
