<template>
	<v-tooltip top color="var(--gris-secondary)" transition="none">
		<template v-slot:activator="{ on, attrs }">
			<v-btn id="hide-all-selected-areas" text class="maphideallselectedareas" @click="maphideallselectedareas" :disabled="saving" v-bind="attrs" v-on="on">
				<v-icon v-if="!saving" class="fw-bold" size="12">fas fa-broom </v-icon>
				<v-progress-circular v-else indeterminate :color="primaryColor" style="width: 24px"></v-progress-circular>
			</v-btn>
		</template>
		<span class="fs-regular">{{ $t('map.hideallselectedareas') }}</span>
	</v-tooltip>
</template>

<script>

export default {
	name: 'maphideallselectedareas',
	props: {
		width: {
			default: 32
		},
		source: {
			type: String,
			default: 'others'
		}
	},
	data() {
		return {
			map: null,
			saving: false,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	methods: {
		maphideallselectedareas(){
			this.$root.$emit('hide-all-selected-areas');
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maptools {
	& .maphideallselectedareas {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: let(--negative);
	}
}
</style>
