<template>
	<div class="mapidentifier rounded elevation-1 pa-0" :style="left ? { left: left, bottom: bottom } : { right: right, bottom: bottom }">
		<v-btn text class="btnlevel d-flex justify-center align-center pa-2" :ripple="false">
			<span class="fw-bold fs-regular">{{ name }}</span>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'MapIdentifier',
	props: {
		name: {
			type: String,
			required: true
		},
		left: {
			required: false
		},
		right: {
			required: false
		},
		bottom: {
			type: String,
			required: false,
			default: '2rem'
		}
	},
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {},
	watch: {},
	methods: {}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.mapidentifier {
	position: absolute;
	//transition: all 1s ease-out;
	z-index: 4;
	height: 32px;
	background-color: var(--negative);
	opacity: 0.85 !important;
	& .btnlevel {
		margin: 0px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
		cursor: auto !important;
		font-family: var(--font-family-primary);
		&:hover {
			background: var(--negative) !important;
		}
		&:focus {
			background: var(--negative) !important;
		}
	}
}
</style>
