<template>
	<div class="pt-0 w-100 historic-chart">
		<v-form v-model="pollutantsForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<v-row no-gutters class="px-3">
				<v-col cols="12" :sm="showAdvancedDate ? (form.showChartOptions ? 3 : 4) : 6">
					<v-select
						:label="$t('aqi.dateInterval')"
						toplabel
						clearable
						required
						:disabled="form.formDisabled"
						v-model="form.dateInterval"
						:items="intervals"
						:hide-details="true"
						@input="submitDateInterval"
						class="mt-2 px-1"
					></v-select>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" :sm="form.showChartOptions ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.startDate')"
						toplabel
						:required="!form.startDateDisabled"
						v-model="form.startDate"
						:disabled="form.startDateDisabled"
						:min="getFirstDateParameter()"
						:max="getLastDateParameter() ?? Date.now()"
						:tooltipDescription="form.infoStartDate"
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" :sm="form.showChartOptions ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.endDate')"
						toplabel
						:required="!form.endDateDisabled"
						v-model="form.endDate"
						:disabled="form.endDateDisabled"
						:min="form.startDate"
						:max="getLastDateParameter() ?? Date.now()"
						:tooltipDescription="form.infoEndDate"
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
				<!-- Design options -->
				<v-col cols="12" class="d-flex justify-start align-center" :sm="showAdvancedDate ? 3 : 6" v-if="form.showChartOptions || form.option">
					<v-select
						v-model="form.designOption"
						@input="changeVisualization"
						class="form-control-select mt-2 px-1 pointer"
						:items="form.configurations"
						:hide-details="true"
						required
						:menu-props="{ maxHeight: 'unset' }"
						:label="$t('panels.device.designOptions')"
					>
						<!-- <template v-slot:selection="{ item }">
							<v-tooltip v-if="item.value.description" left max-width="350" :color="primaryColor">
								<template v-slot:activator="{ on, attrs }">
									<div class="d-flex" v-bind="attrs" v-on="on">
										<v-icon class="px-2 fw-500 fs-md">fa-kit fa-line-info-2</v-icon>
										<span>{{ item.text }}</span>
									</div>
								</template>
								<span class="fw-regular fs-regular text-center">{{ item.value.description }}</span>
							</v-tooltip>
							<span v-else class="pl-2">{{ item.text }}</span>
						</template> -->
						<template v-slot:item="{ item }">
							<v-tooltip v-if="item.value.description" left nudge-left="10" max-width="350" color="var(--gris-secondary)">
								<template v-slot:activator="{ on, attrs }">
									<div class="width-100 height-100" v-bind="attrs" v-on="on">
										<!-- <v-icon class="px-2 fw-500 fs-md">fa-kit fa-line-info-2</v-icon> -->
										<span>{{ item.text }}</span>
									</div>
								</template>
								<span class="fw-regular fs-regular text-center">{{ item.value.description }}</span>
							</v-tooltip>
							<span v-else>{{ item.text }}</span>
						</template>
					</v-select>

					<echart-show-interval
						v-if="form.chartData && enableShowIntervals"
						:elasticData="form.chartData"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:showIntervals="form.showIntervals"
						@toggleShowIntervals="toggleShowIntervals"
						:height="42"
					></echart-show-interval>
					<echart-export-excel
						v-if="form.designOption && form.designOption.exportable == 1"
						:option="form.option ?? {}"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:dateInterval="form.dateInterval"
						:bgColor="primaryColor"
						iconColor="white"
						:chartInstance="chartInstance"
						spacingClass="mt-4"
						:height="42"
						:zoomedProps="!isOriginalZoom ? ['initChart', pollutant, zoomedDocuments, form.designOption] : []"
					></echart-export-excel>
				</v-col>
			</v-row>
		</v-form>

		<div
			class="width-100 d-flex justify-center align-center px-6p"
			:style="displayLocation == 'card' ? '' : fullscreenDialog ? 'height: calc(100vh - 187px)' : 'height: calc(70vh - 118px)'"
		>
			<!-- Loading animation -->
			<div class="d-flex justify-center align-center" v-if="loadingAnimation && !form.option" :style="chartStyle">
				<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
			</div>
			<v-chart
				v-if="form.option"
				:loading="loadingAnimation"
				:option="form.option"
				:style="chartStyle"
				autoresize
				ref="historicChart"
				:class="displayLocation == 'dialog' ? 'pb-3' : 'pb-0'"
				@datazoom="debouncedZoom"
			></v-chart>
		</div>
	</div>
</template>

<script>
import HistoricChartMixin from '@/mixins/HistoricChartMixin';
import { search, ChartOptions, obtainIndexName } from '@/api/common';
import { /* lastData, */ scrollData } from '@/api/databases_API';
import { query_HistoricDataByParameter } from '@/utils/queries';
import constants from '@/utils/constants';
import { debounce } from 'lodash';

import EchartExportExcel from './functionalities/EchartExportExcel.vue';
import EchartShowInterval from './functionalities/EchartShowInterval.vue';
import EchartShowIntervalMixin from './functionalities/EchartShowIntervalMixin';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
import { Scatter3DChart } from 'echarts-gl/charts';
import { Grid3DComponent } from 'echarts-gl/components';

use([
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent,
	BarChart,
	LineChart,
	PieChart,
	CanvasRenderer,
	Scatter3DChart,
	Grid3DComponent
]);

//import logo_multireload from './logo_multireload.png';
export default {
	name: 'HistoricChart',
	mixins: [EchartShowIntervalMixin, HistoricChartMixin],
	components: {
		VChart,
		EchartExportExcel,
		EchartShowInterval
	},
	props: {
		feature: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],

			form: {
				index: this.pollutant.elasticacronym,
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				chartData: null,
				option: null,
				designOption: null,
				configurations: [],
				showChartOptions: false,
				showIntervals: false
			},
			intervals: [
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 }
				/* { text: this.$t('aqi.customInterval'), value: -1 } */
			],

			pollutantsForm: false,
			loadingAnimation: false,
			chartType: '',
			historicalChartData: [],
			chartStyle: {},
			parameterConfiguration: {},
			parameterOption: null,
			saving: false,
			showAdvancedDate: false,
			primaryColor: this.$store.state.global.primaryColor,
			chartInstance: null,
			zoomDebounce: 500,
			zoomedDocuments: [],
			isOriginalZoom: true,
			debouncedZoom: null
		};
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		pollutants() {
			return this.$store.getters.getParameters;
		},
		historicElasticData() {
			return this.$store.getters.getHistoricElasticData[this.pollutant.elasticacronym];
		},
		formHistoric: {
			get() {
				return this.$store.getters.getHistoricStore[this.form.index];
			},
			set(value) {
				this.$store.commit('setFormHistoricObject', { index: this.form.index, data: value });
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		},
		historicFirstDates() {
			return this.$store.getters.getHistoricFirstDates;
		},
		historicLastDates() {
			return this.$store.getters.getHistoricLastDates;
		},
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			}
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		},
		featureClick() {
			return this.$store.getters.getFeatureClick;
		}
	},

	watch: {
		'form.option': {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['historicChart']) {
							this.chartInstance = this.$refs['historicChart']; //.echartsInstance;
							console.log('Chart instance initialized:', this.chartInstance);
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
					if (this.form.chartData && typeof this.form.chartData.message === 'object') {
						this.form.showChartOptions = true;
						if (!this.form.designOption) {
							this.form.designOption = this.form.configurations.find(
								(option) => option.value.id == this.parameterOption.value.id
							).value;
						}
						console.log('call changevisualization: displayMode', this.chart, this.form.designOption);

						if (!this.form.option) this.changeVisualization(this.form.designOption);
					}
				}
			}
		},
		'form.showChartOptions'(val, prev) {
			console.log('watcher chart opt', prev, val);
		},
		'form.showIntervals'(val, prev) {
			console.log('showintervals watcher ', this.displayLocation, prev, val);
		},
		zoomedDocuments(val) {
			console.log('zoomedDocuments watch', val, this.form.chartData.message, this.isOriginalZoom);
		},
		'form.chartData'(val) {
			console.log('chartData watch', val);
		},
		featureClick(val) {
			console.log('featureClick watch', val);
			this.form.configurations = [];
			this.form.designOption = null;
			this.parameterOption = this.configurationByPollutant(this.pollutant, val.ismobile);
		}
	},
	created() {
		this.debouncedZoom = debounce(this.changedZoom, this.zoomDebounce);
	},
	mounted() {
		console.log('======= mounted formHistoric ' + this.displayLocation, this.formHistoric, this.pollutant);
		this.parameterOption = this.configurationByPollutant(this.pollutant, this.featureClick.ismobile);
		if (this.parameterOption) this.parameterConfiguration = this.parameterOption.value.config;
		else this.$puiNotify.warning('Unable to retrieve visualization for parameter', 'Warning');
		this.loadDesignOptions();

		this.chartStyle = this.displayLocation == 'card' ? { height: '306px', width: '100%' } : { height: '100%', width: '100%' };
		if (this.displayLocation == 'dialog') this.restoreChanges();

		if (this.form.chartData && !this.chart) {
			this.form.showChartOptions = true;
			console.log('call changevisualization: mounted', this.chart, this.form.designOption);
			this.changeVisualization(this.form.designOption);
		}
	},
	updated() {
		console.log('======= ' + this.displayLocation + ' updated', this.formHistoric, this.chart);
	},
	beforeDestroy() {
		console.log('======= beforeDestroy formHistoric ');
		//this.deleteChart();
	},
	methods: {
		deleteChart() {
			this.form.option = null;
		},
		configurationByPollutant(parameter, ismobile = 0) {
			/* console.log(
				'config by pollutant',
				this.form.configurations,
				this.parameterConfiguration,
				JSON.parse(parameter.chartconfiguration)['series'][0]['type']
			); */
			console.log('config by pollutant', parameter, ismobile);
			let configuration = null;
			console.log('config by pollutant', parameter, ismobile);
			if (ismobile == 1 && parameter.mobilechartacronym) {
				this.chartType = JSON.parse(parameter.chartconfiguration)['series'][0]['type'];
				configuration = {
					text: parameter.mobilechartname,
					value: {
						config: JSON.parse(parameter.mobilechartconfiguration),
						id: parameter.mobilechartacronym,
						applyranges: parameter.mobileapplyranges,
						exportable: parameter.mobileexportable,
						showintervals: parameter.mobileshowintervals,
						isdynamic: parameter.mobileisdynamic,
						name: parameter.mobilechartname,
						description: parameter.mobilechartdescription
					}
				};
			} else if (ismobile == 0) {
				this.chartType = JSON.parse(parameter.chartconfiguration)['series'][0]['type'];
				configuration = {
					text: parameter.chartname,
					value: {
						config: JSON.parse(parameter.chartconfiguration),
						id: parameter.chartacronym,
						applyranges: parameter.applyranges,
						exportable: parameter.exportable,
						showintervals: parameter.showintervals,
						isdynamic: parameter.isdynamic,
						name: parameter.chartname,
						description: parameter.chartdescription
					}
				};
			}

			return configuration;
		},
		showDesignOptions() {
			this.form.showChartOptions = true;
			if (!this.form.configurations || !this.form.designOption) {
				console.log('showDesignOptions', this.featureClick, this.featureClick.ismobile);
				const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC, this.featureClick.ismobile);
				const promise = search(historicalChartOptions);
				promise.then((historicalChartData) => {
					console.log('Configurations available for tab (HISTORIC): ', historicalChartData.data.data);

					this.historicalChartData = historicalChartData.data.data;
					this.historicalChartData.forEach((visualization) => {
						let existConfiguration = false;
						if (this.form.configurations.length > 0) {
							this.form.configurations.forEach((configuration) => {
								if (configuration.text == visualization['visualizationname']) existConfiguration = true;
							});
						}
						if (!existConfiguration) {
							this.form.configurations.push({
								text: visualization.visualizationname,
								value: {
									config: JSON.parse(visualization.configuration),
									id: visualization.acronym,
									applyranges: visualization.applyranges,
									exportable: visualization.exportable,
									showintervals: visualization.showintervals,
									isdynamic: visualization.isdynamic,
									name: visualization.visualizationname,
									description: visualization.description
								}
							});
						}
					});
					this.form.designOption = this.form.configurations.find((option) => option.value.id == this.parameterOption.value.id).value;
					return this.form.configurations;
				});
			}
			return this.form.configurations;
		},

		changeVisualization(design, elasticData = null) {
			this.loadingAnimation = true;
			this.form.showIntervals = false;
			if (!design) {
				design = this.configurationByPollutant(this.pollutant, this.featureClick.ismobile).value;
			}
			if (typeof design.value == 'object') {
				this.chartType = design.value.config['series'].length > 0 ? design.value.config['series'][0]['type'] : 'line';
				this.parameterConfiguration = design.value.config;
			} else {
				this.chartType = design.config['series'].length > 0 ? design.config['series'][0]['type'] : 'line';
				this.parameterConfiguration = design.config;
			}
			let designOption = this.form.designOption;
			if (this.historicalChartData.length > 0) {
				let visualization = this.historicalChartData.find((vis) => vis.acronym == design.id);
				if (visualization) {
					designOption = {
						config: JSON.parse(visualization.configuration),
						id: visualization.acronym,
						applyranges: visualization.applyranges,
						exportable: visualization.exportable,
						showintervals: visualization.showintervals,
						isdynamic: visualization.isdynamic,
						name: visualization.visualizationname,
						description: visualization.description
					};
				}
				console.debug('debug: design visualization 1', designOption, this.form.designOption);
			}
			if (designOption) {
				console.debug('debug: design visualization 2', this.historicalChartData);
				console.log('viz option: ', designOption.id, designOption.id == constants.VISUALIZATIONS.STACKED_SERIES);
				//this.form.option = this.init3DGraph(this.pollutant, elasticData ?? this.form.chartData, designOption);
				switch (designOption.id) {
					case constants.VISUALIZATIONS.HEATMAP:
						this.form.option = this.initHeatMap(this.pollutant, elasticData ?? this.form.chartData, designOption);
						break;
					case constants.VISUALIZATIONS.STACKED_SERIES:
						this.form.option = this.initStackedSeries(this.pollutant, elasticData ?? this.form.chartData, designOption);
						break;
					case constants.VISUALIZATIONS.GRAPHIC3D:
						this.form.option = this.init3DGraph(this.pollutant, elasticData ?? this.form.chartData, designOption);
						break;
					case constants.VISUALIZATIONS.BAR_MOBILE:
						this.form.option = this.initBarMobile(this.pollutant, elasticData ?? this.form.chartData, designOption);
						break;
					case constants.VISUALIZATIONS.TIME_MOBILE:
						this.form.option = this.initTimeMobile(this.pollutant, elasticData ?? this.form.chartData, designOption);
						break;
					default:
						this.form.option = this.initChart(this.pollutant, elasticData ?? this.form.chartData, designOption, this.form.showIntervals);
				}
			}

			this.loadingAnimation = false;
		},
		submitDateInterval() {
			let d = new Date();
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					this.commitChanges();
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setDate(d.getDate() - this.form.dateInterval);
					this.form.chartData = null;
					let indexName = obtainIndexName(this.featureClick);
					scrollData(
						indexName,
						query_HistoricDataByParameter(this.featureClick.code, this.pollutant.elasticacronym, d.valueOf(), Date.now())
					).then((elasticData) => {
						if (elasticData.message.length > 0) {
							this.$store.commit('setHistoricElasticData', {
								index: this.pollutant.elasticacronym,
								data: elasticData
							});
							this.form.chartData = elasticData;
							this.showDesignOptions();
							console.log('call changevisualization: submitDateInterval', this.chart, this.form.designOption);
							this.changeVisualization(this.form.designOption, elasticData);
						} else {
							this.$store.commit('setHistoricElasticData', {
								index: this.pollutant.elasticacronym,
								data: null
							});
							this.loadingAnimation = false;
							this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							this.form.showChartOptions = false;
							this.deleteChart();
							this.form.chartData = null;
						}
						this.commitChanges();
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.form.chartData = null;
				this.form.showChartOptions = false;
				this.loadingAnimation = false;
				this.commitChanges();
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;
				if (this.form.endDate) this.submitEndDate();
				else this.commitChanges();
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
		},
		submitEndDate() {
			console.log('info: submit end date ' + this.displayLocation, this.form.endDate);
			if (this.form.startDate && this.form.endDate) {
				this.loadingAnimation = true;
				this.form.chartData = null;

				scrollData(
					obtainIndexName(this.featureClick),
					query_HistoricDataByParameter(
						this.featureClick.code,
						this.pollutant.elasticacronym,
						Date.parse(this.form.startDate),
						Date.parse(this.form.endDate)
					)
				).then((elasticData) => {
					console.log('elasticData debug', elasticData);
					console.log(
						query_HistoricDataByParameter(this.featureClick.code, this.pollutant.elasticacronym, this.form.startDate, this.form.endDate)
					);
					if (elasticData.message.length > 0) {
						this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: elasticData
						});
						this.form.chartData = elasticData;
						this.form.showChartOptions = true;
						this.changeVisualization(this.form.designOption, elasticData);
					} else {
						this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: null
						});
						this.loadingAnimation = false;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						this.form.showChartOptions = false;
						this.deleteChart();
						this.form.chartData = null;
					}
					this.commitChanges();
				});
			}
			this.commitChanges();
		},
		commitChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn('log: commiting changes ' + this.displayLocation + '...', { index: this.form.index, data: this.form });
				this.$store.commit('setFormHistoricObject', { index: this.form.index, data: this.form });
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				console.warn(
					'log: restoring changes ' + this.displayLocation + '... ',
					this.$store.getters.getHistoricStore[this.form.index],
					this.formHistoric,
					this.chart,
					this.form.chartData
				);
				this.$store.getters.getHistoricStore[this.form.index]
					? (this.form = this.$store.getters.getHistoricStore[this.form.index])
					: console.error('Note: Problem with historic store', this.$store.getters.getHistoricStore, this.form.index);
				if (!this.form.chartData || !this.form.option) this.deleteChart();
			}
		},
		getFirstDateParameter() {
			return this.historicFirstDates[this.pollutant.elasticacronym] ? this.historicFirstDates[this.pollutant.elasticacronym] : null;
		},
		getLastDateParameter() {
			return this.historicLastDates[this.pollutant.elasticacronym] ? this.historicLastDates[this.pollutant.elasticacronym] : null;
		},
		loadDesignOptions() {
			console.log('loadDesignOptions', this.featureClick, this.featureClick.ismobile);
			const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC, this.featureClick.ismobile);
			const promise = search(historicalChartOptions);
			this.form.configurations = [this.parameterOption];
			promise.then((historicalChartData) => {
				console.log(
					'Configurations available for tab (HISTORIC) debug: ',
					historicalChartData.data.data,
					this.form.configurations,
					this.parameterOption
				);

				this.historicalChartData = historicalChartData.data.data;
				if (!this.parameterOption && this.historicalChartData.length > 0) {
					let visualization = this.historicalChartData[0];
					this.parameterOption = {
						text: visualization.visualizationname,
						value: {
							config: JSON.parse(visualization.configuration),
							id: visualization.acronym,
							applyranges: visualization.applyranges,
							exportable: visualization.exportable,
							showintervals: visualization.showintervals,
							isdynamic: visualization.isdynamic,
							name: visualization.visualizationname,
							description: visualization.description
						}
					};
					this.form.configurations = [this.parameterOption];
					this.form.designOption = this.parameterOption.value;
				}
				this.historicalChartData.forEach((visualization) => {
					let existConfiguration = false;
					if (this.form.configurations.length > 0) {
						this.form.configurations.forEach((configuration) => {
							if (configuration.text == visualization['visualizationname']) existConfiguration = true;
						});
					}
					if (!existConfiguration) {
						this.form.configurations.push({
							text: visualization.visualizationname,
							value: {
								config: JSON.parse(visualization.configuration),
								id: visualization.acronym,
								applyranges: visualization.applyranges,
								exportable: visualization.exportable,
								showintervals: visualization.showintervals,
								isdynamic: visualization.isdynamic,
								name: visualization.visualizationname,
								description: visualization.description
							}
						});
					}
				});
				this.form.configurations.sort((a, b) => {
					const elementA = a.text.toUpperCase();
					const elementB = b.text.toUpperCase();
					return elementA.localeCompare(elementB);
				});
				return this.form.configurations;
			});
		},
		changedZoom() {
			let option = this.chartInstance.getOption();
			console.log('changedZoom', option, option.dataZoom);
			if (this.form.chartData) {
				let documents = this.form.chartData.message.map((hit) => hit._source);
				let filteredDocs = documents.filter(
					(doc, i) =>
						i >= option.dataZoom[0].startValue &&
						i <= option.dataZoom[0].endValue &&
						doc[this.pollutant.elasticacronym] >= option.dataZoom[1].startValue &&
						doc[this.pollutant.elasticacronym] <= option.dataZoom[1].endValue
				);
				console.log('changedZoom 2', documents, filteredDocs);
				this.zoomedDocuments = filteredDocs;
				this.isOriginalZoom = filteredDocs.length == documents.length;
			}
		}
	}
};
</script>

<style lang="postcss">
.historic-chart,
.historic-notification-chart,
.advanced-chart {
	& .chart-historic {
		height: calc(70vh - 187px);
		width: 85vw;
	}
	& #intervals-btn {
		background-color: var(--gris-50);
		border-radius: 8px;
	}
	& #intervals-btn.active {
		color: var(--azul-primary-100) !important;
		background-color: var(--azul-primary-100) !important;
	}
	.v-input__prepend-inner {
		padding-top: 3px;
		padding-left: 4px;
		& .v-icon {
			font-size: 18px;
			font-weight: 500;
		}
	}
}
/* Global CSS for historic and dashboard */
.dashboard-content,
.historic-chart {
	& .vue-echarts-inner > div:last-child > div:last-child {
		bottom: -2px !important;
		right: 6px !important;
	}
	& .vue-echarts-inner > div:last-child > div:last-child > div[style*='cursor: pointer'] {
		background-color: var(--azul-primary-100) !important;
		color: white;
		font-size: 14px !important;
		margin: 0px !important;
		padding: 4px 8px !important;
	}
	& .vue-echarts-inner > div:last-child > div:not(:last-child) {
		padding-bottom: 6px;
	}
}

.advanced-chart {
	& .vue-echarts-inner > div:last-child > div:last-child {
		bottom: 0px !important;
		right: 6px !important;
	}
	& .vue-echarts-inner > div:last-child > div:last-child > div[style*='cursor: pointer'] {
		background-color: var(--azul-primary-100) !important;
		color: white;
		font-size: 14px !important;
		margin: 0px !important;
		padding: 4px 8px !important;
	}
	& .vue-echarts-inner > div:last-child > div:not(:last-child) {
		margin-bottom: 6px;
	}
}
</style>
